//text
$font-size-s: 14px;
$font-size-m: 20px;
$font-size-lg: 24px;
$font-size-xl: 30px;

$title-font-size: 50px;
$title-padding-v: 0px;
$title-padding-h: 0;
$title-color: #393939;

$text-shadow-offset-x: 0;
$text-shadow-offset-y: 0;
$text-shadow-size: 30px;
$text-shadow-color: $color-black;

$text-space-letters: unset;
$text-space-lines: unset;
$text-space-para: 30px;

//button
$j-btn-text-size: 15px;
$j-btn-h-padding: 65px;
$j-btn-radius: 0px;
$j-btn-top-padding: 15px;


$j-btn-bottom-padding: 16px;

$j-btn-long-padding: unset; //min 1px or unset
$j-btn-wide-padding: unset; //min 1px or unset
$j-btn-narrow-padding: unset; //min 1px or unset

//separator
$separator-v-space: 25px;
$separator-length: 150px;
$separator-line-width: 3px;

//component
$component-space-top: 120px;
$component-space-bottom: 0px;

$component-hover-distance: 10px;

$component-shadow-offset-x: 0;
$component-shadow-offset-y: 0;
$component-shadow-size: 20px;
$component-shadow-spread: 0;
$component-shadow-color: $color-shadow-black-opac;

//tabs
$tab-title-head-sapce: 80px;
$tab-title-h-margin: 0px;
$tab-title-foot-space: 15px;

$tab-title-left-space: 30px;
$tab-title-separator-char: '|';
$tab-title-separator-offset: -5px;
$tab-title-separator-weight: 500;

$tab-title-weight: 500;
$tab-title-color: $color-black;
$tab-title-active-weight: 700;
$tab-title-active-color: $color-black;

$tab-content-height: 300px;
$tab-content-width: 0;

$tab-text-head-space: 25px;
$tab-text-color: $color-steel-grey;
$tab-text-size: 18px;
$tab-text-height: 25px;
$tab-text-left-space: 30px;
$tab-text-right-space: 0px;

$tab-text-speed: 1s;

//img-effect
$effect-bg-size-v: auto 100%;
$effect-bg-size-h: 100% auto;
$effect-bg-final-size: auto 104%;

$effect-transition-fast: .3s;
$effect-transition-medium: .5s;
$effect-transition-slow: 1s;
