//category
$buttons-container-head-space: 50px;
$buttons-container-foot-space: 35px;

$category-title-size: 40px;
$category-title-color: $color-black;
$category-title-foot-spacing: 40px;

$category-summary-size: 20px;
$category-summary-height: 26px;
$category-summary-color: $color-steel-grey;

//item
$item-content-size: $font-size-m;
$item-content-color: $color-steel-grey;
$item-content-padding-v: 0;
$item-content-padding-h: 12.5%;
$item-content-para-space: 30px;

$item-price-size: 35px;
$item-price-color: $color-black;
$item-price-height: 20px;
$item-price-head-space: 30px;
$item-price-side-space: 0px;
$item-price-foot-space: -5px;

$item-image-border-type: solid;
$item-image-border-width: 2px;
$item-image-border-color: $color-silver;
$item-image-float:$left;

$item-image-width: 440px;
$item-image-height: 530px;
$item-image-head-space: 60px;
$item-image-foot-space: 60px;
