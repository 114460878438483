.main-footer{
  width: 100%;
  background-color: #c4b79e;
  z-index: 1;
  position: relative;
  padding-top: 35px;

  @include respond-to('small'){
    padding-bottom: 50px;
  }

  @at-root #{&}_padding{
    @at-root #{&}_15{
      padding: 0 15px;
    } //_15
  } //_padding

  @at-root #{&}__rights{
    direction: $dir;
    position: relative;
    top: 35px;

    span{
      color: #fff;
      font-size: 16px;
      font-weight: 300;
    } //span

    @include respond-to('medium'){
      top: unset;
      bottom: 10px;
    }
    @include respond-to('small'){
      float: none !important;

      span{
        font-size: 12px;
      } //span
      img{
        float: #{$right};
      }
    } //---small
  } //__right

  @at-root #{&}__soc{
    direction: $dir;
    display: inline;
    a{
      padding-#{$right}: 15px;
      i{
        color: #fff;
        transition: .2s;
      } //i

      &:hover{
        i{
          color: $color-green;
        } //i
      } //hover
    } //a
    @include respond-to('medium'){
      top: unset;
      bottom: 10px;
    }
    @include respond-to('small'){
      float: none !important;
      bottom: 0;
    } //---small
    @include respond-to('extra-small'){
      position: absolute;
      bottom: 35px;
      #{$left}: 50%;
      transform: translateX(-50%);
    }
  } //__soc
} //main-footer

.footer-contact{
  @at-root #{&}__form {
    @include ph-color($color-navy);
    @include ph-size(18px, 600);
    width: 285px;
    position: relative;

    @include respond-to('large'){
      width: unset;
    } //---large
  } //__form
  @at-root #{&}__title {
    color: $color-navy;
    font-size: 26px;
    font-weight: 800;
    display: block;
  } //__title
  @at-root #{&}__sub {
    color: $color-navy;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 25px;
    display: block;
  } //__sub
  @at-root #{&}__input {
    width: 100%;
    background-color: #d9cfba;
    height: 40px;
    border-radius: 0px;
    border: 1px solid #93918a;
    box-shadow: 4px 4px 14px rgba(0,0,0,.2) inset;
  } //__input
  @at-root #{&}__input-text {
    /*border: 1px solid #000;
    border-right-color: transparent;
    border-left-color: transparent;
    border-bottom-color: #393939;*/
    padding-#{$right}: 15px;
    margin-bottom: 15px;
    color: $color-navy;
    font-weight: 400;
  } //__input-text
  @at-root #{&}__submit {
    border: none;
    background-color: $color-navy;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-top: 20px;
    border: none;
    box-shadow: none;
  } //__input-submit
  @at-root #{&}__checkbox {
    opacity: 0;

    &:checked{
      &+label{
        &:after{
          opacity: 1;
          transform: rotate(-45deg) scale(1);
        } //after
      } //label
    } //checked

  } //__checkbox
  @at-root #{&}__checkbox-label {
    color: $color-navy;
    font-size: 16px;
    font-weight: 400;
    margin-#{$right}: 10px;
  } //__checkbox-label
  @at-root #{&}__checkbox-controller{
    &:before{
      content: '';
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #d9cfba;
      border: 1px solid #93918a;
      /*border-right-color: transparent;
      border-left-color: transparent;
      border-bottom-color: #393939;*/
      display: block;
      position: absolute;
      #{$right}: 0px;
      top: 199px;
    } //before
    &:after{
      content: '';
      position: absolute;
      #{$right}: 4px;
      top: 204px;
      transform: rotate(-30deg) scale(.75);
      border-bottom: solid 1.5px $color-navy;
      border-left: solid 1.5px $color-navy;
      width: 10px;
      height: 6px;
      opacity: 0;
      transition: .25s;
    } //after
  } //__checkbox-controller
} //footer-contact

.footer-links-counter{
  margin-#{$right}: 10px;
  @include respond-to('small'){
    margin: 0;
  }
  &:first-of-type{
    margin-#{$right}: -30px;
    @include respond-to('small'){
      margin: 0;
    }
    .footer-links{
      padding-#{$right}: 5px;
      @include respond-to('small'){
        padding: 0;
      }
    }
  }
}
.footer-links{
  //padding-#{$left}: 5px;
  width: 200px;
  float: #{$left};

  @include respond-to('large'){
    width: auto;
    float: none;
  } //---large


  &:hover{
    .footer-links__border_highlight{
      transform: translateX(0);
      transition: .3s;
    }
  }
  @at-root #{&}__title {
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 20px;
    display: block;
    color: $color-navy;
  } //__title
  @at-root #{&}__border {
    width: 100%;
    height: 2px;
    overflow: hidden;
    background-color: #000;
    margin-top: 20px;
    position: relative;
    border-bottom: 1px solid #eee;
    @at-root #{&}_highlight {
      width: 200%;
      height: 100%;
      background-color: $color-lime;
      background: linear-gradient(to right, $color-navy 0, $color-navy 50%, transparent 100%);
      position: absolute;
      top: 0;
      #{$left}: 0;
      transform: translateX(-100%);
      transition: 2s;
    } //_highlight
  } //__border
  ul {
    li {
      color: $color-navy;
      font-weight: 300;
      font-size: 16px;
      //line-height: 30px;

      a{
        color: $color-navy;
        font-weight: 300;
        font-size: 16px;
        line-height: 30px;

        @include respond-to('small'){
          line-height: 20px;
        }

        &:hover{
          color: #000;
        } //hover
      } //a
    } //li
  } //ul
} //footer-links

.footer-separator{
  width: 100%;
  height: 2px;
  margin-top: 50px;
  background-color: #a9a9a9;
  border-bottom: solid 1px #fff;

  @include respond-to('medium'){
    margin-top: 20px;
    margin-bottom: 30px;
  } //---medium
  @include respond-to('extra-small'){
    margin-bottom: 15px;
  } //---medium
} //footer-separator

.footer-contacts{
  padding: 20px 0;
  display: inline-block;

  @include respond-to('medium'){
    float: none !important;
    padding: 0;
    margin-bottom: 20px;
    text-align: center;
  }
  span{
    color: $color-navy;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    display: inline-block;
    margin-#{$left}: 16px;

    @include respond-to('small'){
      font-size: 14px;
      margin-#{$left}: 5px;
    }

    &:after{
      content: '|';
      #{$left}: -10px;
      position: relative;
      color: #000;
    } //after

    &:last-of-type{
      &:after{
        content: '';
      } //after
    } //last

    a{
      color: #fff;
    } //a
  } //span
} //footer-contacts

.cs_logo_png{
  position: relative !important;
  #{$left}: 0 !important;
  #{$right}: unset !important;
  bottom: unset !important;
  top: 20px !important;
  margin: 0 !important;
  transform: none !important;

  @include respond-to('medium'){
    position: absolute !important;
    top: unset !important;
    bottom: 10px !important;
    left: 50% !important;
    transform: translateX(-50%) !important;
  } //---small
}
