.categories{
  padding-bottom: 280px;

  &__item-page{
    padding-bottom: 50px;
  }

  &__text-title{
    border-top: 1px solid #000;
    display: block;
    padding: 80px 0 30px;
    position: relative;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -.05em;
    color: #000;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto 40px;


    &:after{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 5px;
      background-color: #000;
    }
  }
  &__title-img{
    margin-bottom: 70px;
  } //__title-img
  &__slider{
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    z-index: 2;

    &:before{
      content: url(/assets/images/home_dec3.png);
      position: absolute;
      top: -50px;
      #{$left}: -60px;
      z-index: 0;
    }

    &:after{
      content: url(/assets/images/home_dec2.png);
      position: absolute;
      bottom: -80px;
      #{$right}: -45px;
      z-index: -1;
    }

    &_alter-dec{
      &:before{
        content: url(/assets/images/home_dec1.png);
        #{$right}: -65px;
        #{$left}: unset;
        top: -80px;
      } //before
      &:after{
        content: url(/assets/images/home_dec2.png);
        bottom: -100px;
        #{$right}: -40px;
      } //after
    } //_alter-dec
  } //__slider
  &__item{
    text-align: center;
    margin-bottom: 40px;
    padding: 40px 25px 50px;
    background-color: #fff;
    border: 1px solid transparent;
    transition: .2s;
    position: relative;
    height: 370px;

    &:hover{
      border: 1px solid #e2e2e2;

      .j-btn{
        opacity: 1;
      }
    }

    img{
      display: block;
      margin: 0 auto;
    } //img
  } //__item
  &__title{
    font-size: 17px;
    font-weight: 400;
    color: #878a8e;
    display: block;
  } //__title

  .has-tag{
    &:after{
      #{$left}: 25px;
    }
  }
  .j-btn{
    position: absolute;
    bottom: -27px;
    #{$right}: 25px;
    margin-top: 25px;
    opacity: 0;

    &:last-of-type{
      #{$left}: 25px;
      #{$right}: unset;
    }
    &:after{
      content: url(/assets/images/btn_cart.png);
      position: absolute;
      top: 15px;
      #{$right}: 15px;
    } //after
  } //j-btn

  &__filters{
    margin: -15px 0 40px;
    padding-bottom: 35px;
  } //__filters
  &__search{
    float: $left;
    span{
      font-size: 18px;
      font-weight: 400;
      color: #363942;
    } //span
  } //__search
  &__select{
    background-color: #e2e2e2;
    border: none;
    padding: 5px 30px;
    font-size: 18px;
    color: #a6a9ac;
    min-width: 190px;
    margin-#{$right}: 20px;
  } //__select
  &__radio{
    float: $left;
    margin-#{$right}: 65px;
    input{
      margin-#{$right}: 20px;
    }
    span{
      font-size: 18px;
      font-weight: 400;
      color: #363942;
    } //span
    label{
      font-size: 15px;
      font-weight: 400;
      color: #9a9fa6;
      padding-#{$right}: 5px;
    } //label
  } //__radio
} //home-sale
