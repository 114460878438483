// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

@mixin form-color($color-class) {
  span{
    &:last-of-type{
      color: $color-class;
    }
  }
  .article-contact__submit{
    background-color: $color-class;
  }
}

@mixin menu-item-color($color-class) {
  border-top-color: $color-class;

  &:hover, &.header-main__item_selected{
    background-color: $color-class;
    a{
      color: #fff;
    }
  }
}

@mixin home-btn-color($color-class, $bg-color: #fff, $hover-color: #fff){
  color: $color-class;
  background-color: $bg-color;
  border-bottom: solid 3px $color-class;

  p{
    color: #000;
  }
  svg{
    fill: $color-class;
  } //svg
  a{
    color: $color-class;
  } //a

  &:hover{
    color: $hover-color;
    background-color: $color-class;

    .article-block__action{
      &:after{
        background-color: #fff;
      } //after
    } //article-block__action

    svg{
      fill: $hover-color;
    } //svg
    a{
      color: $hover-color;
    } //a
  } //hover
} //home-btn-color

@mixin svg-fill-color($fill-color){
  fill: $fill-color;
}

@mixin ph-color($ph-color){
  ::-webkit-input-placeholder {
    color: $ph-color;
  }
  ::-moz-placeholder {
    color: $ph-color;
  }
  :-ms-input-placeholder {
    color: $ph-color;
  }
  :-moz-placeholder {
    color: $ph-color;
  }
}

@mixin ph-size($ph-size, $font-weight: 400){
  ::-webkit-input-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  ::-moz-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  :-ms-input-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
  :-moz-placeholder {
    font-size: $ph-size;
    font-weight: $font-weight;
  }
}

@mixin respond-to($breakpoint) {
  $raw-query: map-get($breakpoints, $breakpoint);

  @if $raw-query {
    $query: if(
      type-of($raw-query) == 'string',
      unquote($raw-query),
      inspect($raw-query)
    );

    @media #{$query} {
      @content;
    }
  } @else {
    @error 'No value found for `#{$breakpoint}`. '
         + 'Please make sure it is defined in `$breakpoints` map.';
  }
}

@mixin box-shadow($top, $left, $bottom, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow:inset $top $left $bottom $blur $color;
    -moz-box-shadow:inset $top $left $bottom $blur $color;
    box-shadow:inset $top $left $bottom $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $bottom $blur $color;
    -moz-box-shadow: $top $left $bottom $blur $color;
    box-shadow: $top $left $bottom $blur $color;
  }
}

@mixin rounded($radius: 0.5em) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin gradient($from, $to) {
  background: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background: -moz-linear-gradient(top,  $from, $to);
  filter:  progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}');
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}

@mixin transition($args) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}
