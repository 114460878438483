.article-block{
  width: 960px;
  height: 220px;
  box-shadow: 0 0 25px rgba(0,0,0,0.1);
  padding: 20px;
  transition: .2s;
  margin-bottom: 20px;
  position: relative;

  @at-root #{&}__action{
    color: #000;
    position: relative;
    top: 25px;
    font-size: 16px;

    &:after{
      width: 110px;
      height: 1px;
      background-color: #b1b1b1;
      position: absolute;
      top: -10px;
      #{$right}: 265px;

      content: '';
    }
  } //__action

  img{
    float:$right;
    margin-#{$left}: 30px;
  }

  span{
    font-size: 22px;
    margin-bottom: 10px;
    font-weight: 900;
    display: block;
  }
  p{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
}

.article-contact{
  box-shadow: 0 0 25px rgba(0,0,0,0.1);
  margin-top: 30px;
  padding-top: 15px;

  span{
    display: block;
    line-height: 20px;

    &:first-of-type{
      font-size: 16px;
      font-weight: 400;
    }
    &:last-of-type{
      font-size: 24px;
      font-weight: 800;
      margin-bottom: 20px;
    }
  }
  @at-root #{&}_color{
    @at-root #{&}_green{
      @include form-color($color-green);
    }
  }
  @at-root #{&}__form{
    width: 100%;
    padding: 15px;

    @include ph-color(#777)
    @include ph-size('16px');
  } //__form
  @at-root #{&}__input{
    width: 100%;
    margin-top: 10px;
    padding-bottom: 10px;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  } //__input
  @at-root #{&}__submit{
    height: 40px;
    color: #fff;
    margin-top: 20px;
    padding-bottom: 0;
  }

  @at-root #{&}__area{
    resize: none;
    height: 65px;
  }
} //article-contact

.article-min-height{
  min-height: 1500px;

  @at-root #{&}_extra10{
    min-height: 1510px;
  }
}
