$j-form-input-corner: 4px;
$j-form-input-border: none;
$j-form-input-placeholder-color: $color-black;
$j-form-input-foot-space: 8px;

$j-form-text-input-width: 100%;
$j-form-text-input-height: 35px;
$j-form-text-input-bg: $color-white;

$j-form-textarea-width: 100%;
$j-form-textarea-height: 200px;
