@-webkit-keyframes home-scroller {
  0% {
    bottom: 7px;
  }
  50% {
    bottom: 27px;
  }
  100% {
    bottom: 7px;
  }
} //-webkit-keyframes home-scroller
@keyframes home-scroller {
  0% {
    bottom: 7px;
  }
  50% {
    bottom: 27px;
  }
  100% {
    bottom: 7px;
  }
} //keyframes home-scroller
