.staff-main{
  width: 100%;
  max-width: 750px;
  padding: 0 60px;
  margin: 100px auto 0;

  @include respond-to('extra-small'){
    padding: 0 15px;
  }

  &__image{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 1px solid #dbdbdb;
    background-position: center;
    background-size: cover;
    filter: grayscale(1);
    transition: filter .3s;
  } //__image
  &__link{
    &:hover{
      .staff-main__image{
        filter: grayscale(0);
      }
    } //hover
  } //__link
  &__info{
    margin-bottom: 75px;
  } //__info
  &__title{
    margin: 0;
    color: $color-navy;
    font-size: 25px;
    position: relative;

    &:after{
      content: '';
      height: 1px;
      width: 460px;
      max-width: 100%;
      position: absolute;
      bottom: 0;
      #{$right}: 0;
      background-color: #c3b69c;
    }
  } //__title
  &__text{
    font-size: 18px;
    line-height: 23px;
    color: #939393;
    margin-top: 10px;
  } //__text
  &__contacts{
    margin-top: 10px;

    &_lawyer{
      padding-bottom: 30px;
    } //_lawyer
    a{

    } //a
    img{
      margin-#{$left}: 15px;
    }
    span{
      font-size: 18px;
      font-weight: 700;
      color: $color-navy;
    } //span
  } //__contacts
} //staff-main

.lawyer{
  &__side{

  } //__side
  &__form{
    padding: 25px 10px;
  } //__form
  &__form-title{
    font-size: 18px !important;
    display: block;
    text-align: center;
    font-weight: 700  !important;
    margin-bottom: 25px !important;
    color: $color-navy !important;
  } //__form-title
  &__input{
    height: 40px;
    width: 100%;
    padding-#{$right}: 15px;
    margin-bottom: 10px;
    border: 1px solid #e5ded0;

    @include placeholder{
      font-size: 18px;
      color: #939393;
    }

    &_textarea{
      resize: none;
      height: 215px;

      @include respond-to('small'){
        height: 80px;
      }
    } //_textarea
  } //__input
  &__submit{
    width: 130px;
    border: none;
    color: #fff;
    font-weight: 700;
    background-color: $color-navy;
    height: 40px;
    font-size: 18px;
  } //__submit
  &__main{
    padding-#{$left}: 30px;

    @include respond-to('small'){
      padding-#{$left}: 0;
    }
  } //__main
  &__title{
    margin-top: 65px;
    font-size: 25px;
    color: $color-navy;
    border-bottom: 1px solid #e5ded0;

    @include respond-to('small'){
      margin-top: 15px;
      padding-#{$right}: 15px;
    }

    .lawyer__side-menu & {
      padding-#{$right}: 15px;
      margin-top: 35px;
    }
  }
  &__side-menu{
    padding: 25px 15px 100px;
  } //__side-menu
  &__side-list{
    margin-top: 25px;
    li{
      width: 100%;
    }
  } //__side-list
  &__side-item{
    display: block;
    width: 100%;
    padding: 5px 15px;
    height: 40px;
    background-color: #fff;
    color: #9098a6;
    font-weight: 600;
    font-size: 18px;
    border: 1px solid #e2e4e8;
    margin-bottom: 3px;
    box-shadow: 0 0 10px rgba(0,0,0,.04);

    &_selected{
      color: $color-navy !important;
    } //_selected
    &:hover{
      color: #9098a6;
      background-color: #fbf9f7;
    } //hover
  } //__side-item
  &__text{
    @include respond-to('small'){
      padding: 0 15px;

      p{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
} //lawyer
