.banner-wrapper{
  position: relative;

}
.main-banner{
  width: 100%;
  position: relative;
  height: 800px;
  max-height: calc(100vh - 140px);
  overflow: hidden;
  background-size: cover;
  background-position: center;

  @include respond-to('small'){
    max-height: calc(100vh - 400px);
  }

  &__overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to top, #000, transparent);;

    span{
      position: absolute;
      top: calc(100vh - 350px);
      /*left: 50%;
      transform: translateX(-50%);*/
      width: 100%;
      text-align: center;
      color: #fff;
      font-weight: 900;
      font-size: 48px;
      text-shadow: 0 0 5px black;

      @include respond-to('medium'){
        font-size: 30px;
      }
      @include respond-to('extra-small'){
        font-size: 18px;
        top: 150px;
      }
    } //span
  } //__overlay

  img{
    opacity: 0;
  }

  @include respond-to('small'){
    margin-top: 50px;

    img{

    }
  } //---small
  &__single{
    position: relative;
    width: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;


  }
  &__title{
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 100%;
    background-color: $color-red;
    text-align: $right;
    padding: 15px 20px;
    span{
      color: #fff;
      font-size: 20px;
      font-weight: 700;
    }
  } //__title
} //main-banner

.home-main{
  width: 100%;
  max-width: 1760px;
  padding: 0 15px 250px;
  margin: 0 auto;

  @include respond-to('small'){
    padding: 0 15px;
  }

  &__link{
    position: relative;
    display: block;
    &:after{
      content:url(../images/link_arrow.png);
      position: absolute;
      bottom: 10px;
      #{$left}: 15px;
      transition: 1s;
    } //after

    &:hover{
      &:after{
        bottom: 18px;
        transition: .2s;
      } //after
    } //hover
  } //__link

  &__title{
    font-size: 25px;
    font-weight: 600;
    margin: 0;
    color: #2A4F8F;
    padding: 0 70px;

    @include respond-to('small'){
      font-size: 20px;
      padding:  0 15px;
    }
  } //__title
  &__frame{
    border: 1px solid #d1c3a5;
    border-radius: 2px;
    background-color: #fff;
    padding: 60px 0;
    height: 270px;
    margin-bottom: 30px;
    position: relative;
    box-shadow: 0 0 21px rgba(0,0,0,.04);
    transition: 1s;
    bottom: 0;

    @include respond-to('huge'){
      padding: 15px 0;
    }

    &:hover{
      box-shadow: 0 0 21px rgba(0,0,0,.1);
      bottom: 8px;
      transition: .2s;
    } //hover

    &_freeze{
      &:hover{
        bottom: 0;
      }
    } //_freeze
  } //__frame

  &__news{
    background-color: #f3f1ee;
  } //__news

  &__ticker{
    ul{
      li{
        height: 160px;
        padding-top: 20px;
        padding-#{$left}: 20px;

        span{
          display: block;
          font-size: 14px;
          line-height: 18px;
          font-weight: 400;
          color: #939393;
        } //span
        a{
          display: block;
          font-size: 16px;
          font-weight: 600;
          line-height: 18px;
          color: #2a4f8f;

          &:after{
            content: '>>';
            padding-#{$right}: 10px;
          }
        } //a
      } //li
    } //ul
  } //__ticker

  &__text{
    font-size: 18px;
    color: #000;
    line-height: 23px;
    font-weight: 400;
    margin-#{$left}: 60px;
    padding-#{$right}: 70px;
    padding-top: 20px;
    margin-top: 10px;
    border-top: 1px solid #d1c3a5;

    @include respond-to('huge'){
      margin-#{$left}: 15px;
      padding-#{$right}: 15px;
      padding-top: 10px;
    }
    @include respond-to('extra-small'){
      font-size: 17px;
    }

    &_list{
      li{
        display: inline-block;
        &:after{
          content: '•';
          margin: 5px;
          color: #2a4f8f;
        }
        a{
          color: #2a4f8f;

          &:hover{
            border-bottom: 1px solid #2a4f8f;
          }
        } //a
      } //li
    } //_list
  } //__text
} //home-main

.home-logo{
  text-align: center;
  padding-top: 10px;
  padding-bottom: 70px;

  h1{
    margin: 0;
  }
}

.home-content{
  max-width: 100%;
  width: 1130px;
  text-align: $right;
  margin: 180px auto 0;

  &__text-side{
    float: $right;
    background-color: #fff;
    width: 53%;
    padding: 110px 100px 80px;
  } //__text-side
  &__text{
    p{
      font-size: 18px;
      line-height: 26px;
      letter-spacing: -.01em;
      color: #000;
      margin-bottom: 35px;
    }
  } //__text
  &__title{
    font-size: 45px;
    font-weight: 700;
    color: #000;
    letter-spacing: -.05em;
    margin-bottom: 35px;
    display: block;
  } //__title
  &__img-side{
    float: $right;
    width: 47%;
    height: 580px;
    background-position: center;
    background-size: cover;
  }
} //home-content

.home-buttons{
  position: relative;

  &__btn{
    height: 680px;
    position: relative;
    top: -75px;
    text-align: center;
    padding: 120px 15px;
    box-shadow: none;
    transition: .3s;
    background-color: #fafcfd;

    &:hover{
      background-color: #fff;
    } //hover
    //border-width: 1px .5px 1px .5px;
  } //__btn
  &__content{
    font-size: 18px;
    font-weight: 500;
    color: $color-navy;
    text-align: $right;
    padding: 0 90px;

    ul{
      list-style-type: square;
      margin-#{$right}: 20px;
      li{
        height: 40px;
      }
    }
  } //__content
  &__title{
    color: $color-navy;
    font-size: 38px;
    font-weight: 700;
    margin: 30px 0;
  } //__title
} //home-buttons

.home-bg{
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
} //home-bg

.home-support{
  height: 770px;
  position: relative;

  &__square{
    background-color: $color-deep-blue;
    color: #e4eff4;
    display: inline-block;
    padding: 87px 67px;
    top: 250px;
    position: relative;

    span{
      font-size: 37px;
      font-weight: 400;
      //letter-spacing: -.04em;

      &:first-of-type{
        font-size: 95px;
        font-weight: 300;
        letter-spacing: -.08em;
      } //1st
    }
  } //__squre
  &__content{
    width: 600px;
    height: 100%;
    background-color: $color-light-blue;
    float: $left;
    position: relative;
    top: 140px;
    z-index: 2;
    padding: 0 100px;
    color: $color-navy;
  } //__content
  &__title{
    font-size: 96px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -.04em;
    margin: 100px 0 70px;
  } //__title
  &__text{
    ul{
      list-style-type: square;
      margin-#{$right}: 20px;
      padding: 30px;
      li{
        margin-bottom: 40px;
      }
    }
  } //__text
} //home-support

.box{
  width: 100%;
  padding-top: $box-head-space;
  padding-bottom: $box-foot-space;
  position: relative;

  @at-root #{&}__strip{
    height: $box-strip-width;
    margin-top: $box-strip-top;

    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //__strip

  @at-root #{&}__title{
    text-align: center;
    position: relative;
    font-size: $box-title-size;
    padding: 0 0 40px;

    @at-root #{&}_has-separator{
      &:after{
        width: 100%;
        content: '';
        height: 2px;
        box-sizing: border-box;
        border-top: 1px solid #000;
        border-bottom: 1px solid #fff;
        position: absolute;
        bottom: 0;
        #{$left}: 0;
        opacity: .15;
      }
    }

    @at-root #{&}_strong{
      font-weight: 700;
    }
  } //__title

  @at-root #{&}__sub-title{
    font-size: $box-subtitle-size;
    text-align: center;
    position: relative;
  } //__sub-title

  @at-root #{&}__content{
    font-size: $box-content-size;
    line-height: $box-content-line;
    position: relative;

    p{
      font-size: inherit;
      margin-bottom: $box-content-p-bottom;
    } //p
    @at-root #{&}_block{
      @at-root #{&}_s{
        width: $box-content-block-small;
        min-width: $box-content-block-small-min;
      } //_s
      @at-root #{&}_m{
        width: $box-content-block-medium;
        min-width: $box-content-block-medium-min;
      } //_m
      @at-root #{&}_l{
        width: $box-content-block-large;
        min-width: $box-content-block-large-min;
      } //_l
      @at-root #{&}_full{
        width: 100%;
      } //_l
    } //_block
  } //__content

  @at-root #{&}__img{
    @at-root #{&}_height{
      @at-root #{&}_full{
        max-width: unset;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
      } //_full
    } //_height
  } //__img

  @at-root #{&}__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    #{$left}: 0;
    z-index: -1;

    @at-root #{&}_color{
      @at-root #{&}_black{
        background-color: $color-black;
      } //_black
      @at-root #{&}_white{
        background-color: $color-white;
      } //_white
      @at-root #{&}_peach{
        background-color: $color-peach;
      } //_peach
    } //_color
    @at-root #{&}_opacity{
      @at-root #{&}_half{
        opacity: .5;
      } //_half
      @at-root #{&}_third{
        opacity: .33;
      } //_half
    } //_opacity
  } //__background

  @at-root #{&}_bg{
    @at-root #{&}_grey{
      background-color: #939393;
    }
    @at-root #{&}_light-grey{
      background-color: $color-light-grey;
    }
    @at-root #{&}_white{
      background-color: $color-white;
    } //_white
    @at-root #{&}_offwhite{
      background-color: $color-offwhite;
    } //_offwhite
    @at-root #{&}_black{
      background-color: $color-black;
    } //_black
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
    @at-root #{&}_cream{
      background-color: #f6f7ee;
    } //_cream
    @at-root #{&}_deep-blue{
      background-color: $color-deep-blue;
    } //_deep-blue
    @at-root #{&}_aqua{
      background-color: $color-aqua;
    } //_aqua
    @at-root #{&}_green{
      background-color: $color-green;
    } //_green
    @at-root #{&}_img{
      background-image: url($box-bg-img);
    } //_img
  } //_bg

  @at-root #{&}_border{
    @at-root #{&}_grey{
      border: solid $box-border-width $color-grey;
    } //_grey
  } //_border

  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
} //box

.mobile-no-padding{
  @include respond-to('small'){
    padding-top: 0;
  }
}

.buttons-container{
  display: block;
  position: relative;
  text-align: center;
  margin-#{$left}: $button-spacing * -1;
  margin-#{$right}: $button-spacing * -1;
  padding-top: $button-head-space;

  @at-root #{&}__button{
    display: inline-block;
    margin: 0 $button-spacing / 2;
    width: $button-width;
  } //button
  @at-root #{&}__icon{
    display: block;
    margin: $center-margin;
  } //icon
  @at-root #{&}__title{

  } //title

  @at-root #{&}_padding-top{
    @at-root #{&}_small{
      padding-top: $buttons-container-head-space;
    } //_small
  } //_margin-top

  @at-root #{&}_padding-bottom{
    @at-root #{&}_small{
      padding-bottom: $buttons-container-foot-space;
    } //_small
  } //_padding-bottom

  @at-root #{&}_bg-color{
    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //_bg-color
} //buttons-container

.slider-container{
  text-align: center;
  position: relative;
  margin-top: $slider-head-space;

  @at-root #{&}__item{
    padding: $slider-item-head-space $padding-base $slider-item-foot-space;
    position: relative;

    @at-root #{&}_size{
      @at-root #{&}_m{
        width: $slider-item-width;
        height: $slider-item-height;
      } //_m
    } //_size

    @at-root #{&}_onsale{
      &:after{
        content: url(../images/onsale.png);
        position: absolute;
        top: 0;
        #{$left}: 0;
      } //after
    } //_onsale
  } //__item
  @at-root #{&}__image{
    display: block;
    margin: $center-margin;
  } //image
  @at-root #{&}__text{
    position: absolute;
    bottom: $slider-item-foot-space;
    width: 100%;
    margin-#{$left}: $padding-base * -1;
    margin-#{$right}: $padding-base * -1;
  } //__text
  @at-root #{&}__title{
    font-size: $slider-title-size;
    color: $slider-title-color;
    position: relative;

  } //__title
  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    transition: .2s;

    &:hover{
      opacity: 1;
    } //hover
  } //__overlay

  @at-root #{&}__full-transparent{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: $slider-hover-opacity;
    transition: .2s;
  } //__full-transparent
} //slider-container


.home-forms{
  width: 27%;
  position: absolute;
  #{$left}: 0;
  top: 150px;


  @at-root #{&}_bg{
    @at-root #{&}_aqua{
      background-color: $color-aqua !important;
    } //_aqua
  } //_bg
  @at-root #{&}_color{
    @at-root #{&}_aqua{
      color: $color-aqua !important;
    } //_aqua
  } //_bg

  @at-root #{&}__form{
    background-color: #fff;
    width: 360px;
    padding: 30px;
    padding-top: 45px;
    padding-bottom: 55px;
    margin-bottom: 25px;
    text-align: center;
    position: relative;
    //float:$left;
    @include ph-color('#a19999');
    @include ph-size('16px');
  } //__form
  @at-root #{&}__title{
    color: #fff;
    font-size: 18px;
    line-height: 28px;
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 50px;
    background-color: #6e2492;
    padding-top: 10px;

    span{
      &:first-of-type{
        font-size: 18px;
        font-weight: 500;
      } //last
    } //span
  } //__title
  @at-root #{&}__components{
    @at-root #{&}_margin-top{
      @at-root #{&}_none{
        margin-top: 0 !important;
      } //_none
    } //_margin-top
    @at-root #{&}_width{
      @at-root #{&}_full{
        width: 100% !important;
      } //_full
    } //_width
    select{
      background-color: #efeeee;
      margin-bottom: 40px;
      border: solid 1px #dcdcdc;
      height: 35px;
    } //select
    input{
      &[type='text']{
        width: 46%;
        float:$right;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-#{$right}: 12px;
        margin-bottom: 20px;
        margin-top: 35px;
        background-color: #efeeee;

        &:first-of-type{
          margin-#{$left}: 8%;
        }
      } //text
      &[type='email']{
        width: 100%;
        height: 35px;
        border: solid 1px #dcdcdc;
        padding-#{$right}: 12px;
        margin-bottom: 40px;
        background-color: #efeeee;

      } //email
      &[type='submit']{
        width: 100%;
        text-align: center;
        color: #6e2492;
        height: 50px;
        font-size: 28px;
        box-shadow: 1px 3px 5px rgba(0,0,0,.2);
        border: solid 1px #cbcaca;
        background-color: #fff;

      } //submit
    } //input
  } //__components
} //home-form

.slick-dots{
  top: 82vh;
  z-index: 1;

  li{
    &.slick-active{
      button{
        background-color: #000;
      }
    } //slick-active
    button{
      border-radius: 50%;
      background-color: #cdd3cd;
      border: none;
    } //button
  } //li
} //slick-dots

.home-button{
  display: block;
  transition: .3s;
  box-shadow: 0 0 20px rgba(0,0,0,.15);
  height: ($home-box-height - $home-box-btn-space) / 2;
  margin-bottom: $home-box-btn-space;
  padding-top: 40px;

  &:active{
    position: relative;
    top: 1px;
    #{$left}: 1px;
    box-shadow: 0 0 5px rgba(0,0,0,.3);
  }

  svg{
    display: block;
    margin: 0 auto;
  }

  span{
    text-align: center;
    display: block;
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 800;
  } //span

  &:hover{

    span{
      color: #fff;
    } //span
    img{
      filter: invert(1);
    }
  } //hover

  @at-root #{&}__big-text{
    width: 400px;
    position: absolute;
    bottom: 45px;
    #{$left}: 35px;
    pointer-events: none;
  } //__big-text
  @at-root #{&}__big{
    color: #fff;

    &:hover{
      color: #fff;
    }

    p{
      color: inherit;
      font-size: 22px;
      font-weight: 300;
      letter-spacing: -.02em;
    } //p
  } //__big
  @at-root #{&}__link{
    font-size: 28px;
    letter-spacing: -.02em;
    float:$left;
  } //__link

  @at-root #{&}__texts{
    position: absolute;
    bottom: 0;
    #{$right}: 0;
    width: 100%;
    z-index: 3;
    padding: 40px;
    pointer-events: none;

    span{
      text-align: center;
      font-size: 22px;
      font-weight: 800;
    } //span
  } //__texts
  position: relative;
  img{
    display: block;
    position: relative;
    margin: 0 auto;
  } //img

  @at-root #{&}_effect{
    @at-root #{&}_hover-shadow{
      span, p{
        text-shadow: none;
        transition: .4s;
      } //span p


      &:hover{
        span, p{
          text-shadow: 4px 2px 10px black;
        } //span p
      } //hover
    } //_hover-shadow
  } //_effect
} //home-button

.home-title{
  font-size: 26px;
  font-weight: 800;
  padding-bottom: 30px;
  margin-bottom: 30px;
  display: block;
  position: relative;

  @at-root #{&}_line{
    @at-root #{&}_black{
      &:after{
        background-color: #000 !important;
      } //after
    } //_black
  } //_line

  &:after{
    content: '';
    background-color: #fff;
    position: absolute;
    bottom: 0;
    #{$left}: 0;
    height: 1px;
    width: 100%;
  }
}

.fill{
  @at-root #{&}_orange{
    @include svg-fill-color($color-orange);
  } //_orange
  @at-root #{&}_green{
    @include svg-fill-color($color-green);
  } //_green
  @at-root #{&}_turquise{
    @include svg-fill-color($color-turquise);
  } //_turquise
  @at-root #{&}_blue{
    @include svg-fill-color($color-deep-blue);
  } //_blue
  @at-root #{&}_fuxia{
    @include svg-fill-color($color-fuxia);
  } //_fuxia
  @at-root #{&}_red{
    @include svg-fill-color($color-red);
  } //_red
  @at-root #{&}_white{
    @include svg-fill-color($color-white);
  } //_white
} //fill

.home-contact{
  height: 80px;
  padding: 10px;
  position: relative;

  @include respond-to('medium'){
    height: 210px;
  }
  @include respond-to('small'){
    height: 310px;
  }

  &:after{
    content: url(../images/form_arrow.png);
    position: absolute;
    top: -6px;
    #{$right}: 450px;
  }

  &__titles{
    width: 21%;
    float: $right;

    @include respond-to('small'){
      width: 100%
    }
    h1{
      margin: 0;
      font-size: 30px;
      font-weight: 300;
      line-height: 28px;
    }
    span{
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
    }
  } //__titles
  &__inputs{
    width: 79%;
    float: $right;
    padding-top: 12.5px;

    @include respond-to('small'){
      width: 100%
    }

  } //__inputs
  &__form{
    @include ph-color(#fff);
    @include ph-size(18px, 300);
  } //__form
  &__input{
    height: 35px;
    background-color: #99bf54;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    padding-#{$right}: 10px;
    border: 1px solid #86a84a;
    border-bottom-color: #ebffc7;
    width: 100%;

    @include respond-to('medium'){
      margin-bottom: 10px;
    }
  } //__input
  &__submit{
    background-color: $color-lilach;
    border: none;
    padding-#{$left}: 6px;
  } //__submit
} //home-contact

.home-btn{
  text-align: center;
  border: 1px solid #d9d9d9;
  width: 25%;
  float: $right;
  height: 320px;
  transition: .2s;
  cursor: pointer;

  &:not(:last-of-type){
    border-left: none;
  }

  &:hover{
    border-bottom-color: $color-lilach;
    box-shadow: 0 0 25px rgba(0,0,0,0.05);
    .home-btn__border{
      border-bottom-color: $color-lilach;
    }
  }

  &__border{
    border-bottom: 4px solid transparent;
    width: 100%;
    height: 100%;
    padding: 30px 30px 0;
    transition: .2s;
  }

  &__title{
    font-size: 26px;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 15px;
    display: block;
    color: $color-lilach;
  } //__title
  &__desc{
    font-size: 18px;
    font-weight: 300;
    line-height: 26px;
    color: $color-steel-grey;
  } //__desc
  img{
    display: block;
    margin: 0 auto;
  } //img
} //home-btn

.home-squares{
  height: 400px;
  position: absolute;
  top: -300px;
  width: 100%;
}

.home-square{
  width: 100%;
  height: 400px;
  &__image{
    background-color: #fff;
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  } //__image
  &__text{
    background-color: $color-fuxia;
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
      color: #fff;
      font-size: 53px;
      white-space: pre;
    }
  } //__text
  &_framed{
    outline: 1px solid #fff;
    outline-offset: -45px;
  } //_framed
} //home-square

.home-upper{
  padding-top: 180px;
  text-align: center;

  &__text{
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -.01em;
    font-weight: 400;
    width: 800px;
    margin: 40px auto 80px;
  }
} //home-upper

.home-sale{

  &__price{
    font-size: 22px;
    color: #878a8e;
    display: block;
    margin-top: 5px;
    i{
      font-size: 16px;
    } //i
  } //__price
  &__text-title{
    border-top: 1px solid #000;
    display: block;
    padding: 80px 0 30px;
    position: relative;
    font-size: 45px;
    font-weight: 700;
    letter-spacing: -.05em;
    color: #000;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto 40px;


    &:after{
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      width: 20px;
      height: 5px;
      background-color: #000;
    }
  }
  &__title-img{
    margin-bottom: 70px;
  } //__title-img
  &__slider{
    background-color: #fff;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    z-index: 2;

    &:before{
      content: url(../images/home_dec3.png);
      position: absolute;
      top: -50px;
      #{$left}: -60px;
      z-index: 0;
    }

    &:after{
      content: url(../images/home_dec2.png);
      position: absolute;
      bottom: -80px;
      #{$right}: -45px;
      z-index: -1;
    }

    &_alter-dec{
      &:before{
        content: url(../images/home_dec1.png);
        #{$right}: -65px;
        #{$left}: unset;
        top: -80px;
      } //before
      &:after{
        content: url(../images/home_dec2.png);
        bottom: -100px;
        #{$right}: -40px;
      } //after
    } //_alter-dec
  } //__slider
  &__item{
    text-align: center;
    padding: 40px 25px 30px;
    background-color: #fff;
    img{
      display: block;
      margin: 0 auto;
    } //img
  } //__item
  &__title{
    font-size: 17px;
    font-weight: 400;
    color: #878a8e;
    display: block;
  } //__title
  .j-btn{
    position: relative;
    margin-top: 25px;
    &:after{
      content: url(../images/btn_cart.png);
      position: absolute;
      top: 15px;
      #{$right}: 35px;
    } //after
  } //j-btn
} //home-sale

.home-buttons{
  text-align: $left;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;

  &__next{
    margin-top: 30px;
    margin-button: 55px;
    font-size: 27px;
    color: #000;
    font-weight: 600;
    position: relative;
    display: inline-block;
    text-align: left;
    cursor: pointer;

    &:after{
      content: url(../images/arrow.png);
      position: absolute;
      #{$right}: -40px;
      top: 0px;
    }
  } //__next
} //home-buttons

.home-background{
  background-image: url(../images/home_bg.png);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 400px;
}

.sb-slidebar{
  z-index: 102;
}

.desktop.accessibility_wrapper{
  z-index: 101 !important;
}
