.home-sideform{
  position: fixed;
  z-index: 2;
  top: 0;
  #{$left}: 0;
  height: 90vh;
  min-height: 800px;
  width: 730px;
  background-color: #693089;
  transition: transform .5s, box-shadow .2s;
  box-shadow: 0 0 20px rgba(0,0,0,0.5);

  @at-root #{&}_hidden{
    transform: translateX(-100%);
    box-shadow: none;
  } //_hidden

  @at-root #{&}__close-btn{
    position: absolute;
    top: 140px;
    #{$right}: 25px;
    cursor: pointer;
    z-index: 100;
  } //__close-btn

  @at-root #{&}__title{
    color: #fff;
    position: absolute;
    top: 20vh;
    width: 100%;
    text-align: center;
    display: block;
    font-size: 55px;
  } //__title
  @at-root #{&}__form{
    width: 100%;
    position: absolute;
    text-align: center;
    top: 35vh;

    input{
      &[type='text'], &[type='password']{
        border: none;
        height: 60px;
        width: 285px;
        margin: 0 auto 25px;
        display: block;
        position: relative;
        padding-#{$right}: 25px;
      } //text password
      &[type='submit']{
        color: #fff;
        font-size: 30px;
        width: 285px;
        height: 60px;
        margin-top: 80px;
        background-color: $color-aqua;
        border: none;
      } //submit
    } //input
    a{
      color: #fff;
      text-decoration: underline;
      display: block;
      width: 285px;
      text-align:$right;
      margin: 30px auto 0;
    } //a
  } //__form
} //home-sideform




/*.side-form{
  position: fixed;
  top: 0;
  #{$left}: 0;
  width: 250px;
  height: 100%;
  z-index: 20000;
  background-color: $color-white;
  box-shadow: 0 0 20px rgba(0,0,0,.1);
  transition: .3s;

  @at-root #{&}__screen-overlay{
    position: fixed;
    top: 0;
    #{$left}: 0;
    background-color: #000;
    width: 100%;
    height: 100%;
    z-index: 19999;
    display: none;
    opacity: 0;

    @at-root #{&}_active{
      display: block;
    } //_active
  } //__screen-overlay

  @at-root #{&}_hidden{
    transform: translateX(-100%);
    box-shadow: none;
  }

  @at-root #{&}__logo{
    height: 110px;
    padding: 20px;
    margin-bottom: 20px;
  } //__logo

  @at-root #{&}__reveal-btn{
    width: 90px;
    height: 80px;
    background-color: #fff;
    border-radius: 0 40px 40px 0;
    position: absolute;
    #{$right}: -90px;
    top: 360px;
    box-shadow: 0 0 20px rgba(0,0,0,.1);
    z-index: 1;
    cursor: pointer;
    transition: .3s;

    &:after{
      content: '';
      position: absolute;
      width: 19px;
      height: 102%;
      #{$left}: -19px;
      top: 0;
      background-color: #fff;
    } //after - shadow-cover

    >div{
      opacity: 1;
      height: 0;
      transition: .3s;
      padding-top: 22px;
      padding-#{$right}: 10px;
      transition: .3s;

      >img{
        margin-top: 3px;
      }

      span{
        color: #000;
        font-weight: 900;
        line-height: 16px;
        display: block;
        float:$left;
        width: 40px;
      }
    }

    >img{
      opacity: 0;
      transition: .3s;
      float:$left;
    }

    @at-root #{&}_revealed{
      width: 60px;
      #{$right}: -60px;

      >div{
        opacity: 0;

        span{
          display: none;
        }
      }

      >img{
        opacity: 1;
      }
    }
  } //__reveal-btn
  @at-root #{&}__reveal-x{

  } //__reveal-x

  @at-root #{&}__contacts{
    background-color: #f8f8f8;
    width: 100%;
    padding: 20px;
    border-top: solid 1px #d6d6d6;
    border-bottom: solid 1px #d6d6d6;
    height: 230px;

  } //__contacts

  @at-root #{&}__contacts-icn{
    padding-#{$right}: 15px;
    padding-top: 5px;
    width: 20%;
    float:$right;
  } //__contacts-icn

  @at-root #{&}__details{
    padding-#{$right}: 20px;
    width: 80%;
    float:$right;
  } //__details

  @at-root #{&}__phone{
    font-size: 27px;
    font-weight: 900;
    color: $color-green;
    line-height: 24px;
    padding-bottom: 15px;
    display: block;
  } //__phone
  @at-root #{&}__phone-title{
    font-size: 18px;
    color: $color-grey;
    line-height: 20px;
  } //__phone-title
  @at-root #{&}__form{
    padding: 20px;
    border-bottom: solid 1px #d6d6d6;

    input, select, textarea{
      width: 100%;
      margin-bottom: 10px;
      border: solid 1px #d6d6d6;
      border-radius: 4px;
      background-color: #f6f6f6;
      box-shadow: 1px 3px 5px rgba(0,0,0,.1) inset;
      padding: 5px 10px;
    } //input select textarea

    input, select{
      height: 40px;
    } //input select

    textarea{
      resize: none;
      height: 110px;
    } //textarea

    input[type='submit']{
      background-color: $color-green;
      color: $color-white;
      box-shadow: none;
      border: none;
    }
  } //__form

  @at-root #{&}__form-title{
    color: #777;
    font-size: 18px;
    line-height: 20px;
  } //__form-title
  @at-root #{&}__form-sub{
    color: #000;
    font-size: 24px;
    line-height: 20px;
    font-weight: 900;
    margin-bottom: 15px;
  } //__form-sub

  @at-root #{&}__soc{
    background-color: #f8f8f8;
    height: 90px;
    border-bottom: solid 1px #d6d6d6;
  } //__soc
  @at-root #{&}__soc-btn{
    float:$left;
    width: 33%;
    height: 100%;
    text-align: center;
    padding: 15px 10px 0;
    transition: .2s;

    &:not(:last-of-type){
      border-#{$right}: solid 1px #d6d6d6;
    } //not last

    &:hover{
      box-shadow: 0 0 10px rgba(0,0,0,0.15);
    }

    img{
      display: block;
      margin: 0 auto 10px;
    }

    span{
      font-size: 16px;
      line-height: 16px;
      display: block;
      color: #415667;
    }

  } //__soc-btn
} //side-form
*/
