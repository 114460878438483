.item-box{

} //item-box
.similars{
  &__title{
    color: #000;
    font-size: 23px;
    font-weight: 400;
    margin: 30px 0;
    letter-spacing: -.01em;
  }
}

.color-selection{
  width: 100%;
  max-width: 230px;
  margin: 0 35px 0 0;
  padding: 0;

  &__title{
    font-size: 23px;
    letter-spacing: -.01em;
    color: #000;
    font-weight: 400;
    margin: 0 0 35px;
  } //__title

  &__color-container{
    width: 20px;
    height: 20px;
    margin: 5px;
    float: $right;
    cursor: pointer;

    &.selected{
      padding: 0 0 1px;
      border: 1px solid #ccc;
    }
  } //__color-container
  &__color{
    width: 100%;
    height: 100%;
  } //__color
} //color-selection

.item-left{
  padding-#{$right}: 45px;
  &__image{
    position: relative;
    width: 100%;
    height: 390px;
    border: 1px solid #f4f4f4;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    @include respond-to('small'){
      height: 300px;
    } //---small

    &:after{
      content: url(/assets/images/glass.jpg);
      position: absolute;
      bottom: -5px;
      #{$left}: 0;
    }
  } //__image
  &__price-block{
    padding-top: 30px;
    padding-bottom: 30px;
    //border-bottom: 1px solid #e6e6e6;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    }
  } //__price-block
  &__price-text{
    font-size: 32px;
    font-weight: 400;
    letter-spacing: -.01em;
    color: #000;
    padding-#{$left}: 10px;
  } //__price-text
  &__price{
    color: #000;
    font-weight: 700;
    font-size: 38px;

    i{
      font-weight: 700;
      font-size: 25px;
    }

    @include respond-to('small'){
      font-size: 20px;
    }
    @include respond-to('extra-small'){
      display: inline-block;
      margin-#{$right}: 20px;
    }
  } //__price
  &__btn{
    padding: 5px 20px 7px;
    color: #fff;
    font-weight: 400;
    font-size: 35px;
    background-color: $color-fuxia;
    float: $left;
    //box-shadow: -3px 3px 0 rgba(0,0,0,.1);
    transition: .3s;
    filter: none;
    position: relative;
    display: inline-block;
    margin-top: 10px;

    @include respond-to('small'){
      font-size: 20px;
    }

    &:hover{
      color: #fff;
      box-shadow: 2px 2px 10px rgba(0,0,0,.15);
      filter: brightness(100%);
    } //hover

    &:focus{
      color: #fff;
    }

    &:active{
      box-shadow: 0 0 3px rgba(0,0,0,0.5);
      top: 1px;
      left: 1px;
      color: #fff;
    } //active
  } //__btn
  &__secured{
    text-align: $left;
  }
  &__secure{
    display: inline-block;
    margin-top: -2px;
    margin-#{$left}: 10px;
  } //__secure
  &__secure-text{
    font-size: 17px;
    letter-spacing: -.01em;
    color: #878a8e;
  } //__secure-text
} //item-left

.item-text{
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  overflow: hidden;
  transition: .5s;
  max-height: 900px;

  &_closed{
    max-height: 60px;

    .item-text__title{
      //background: linear-gradient(to bottom, #f0f0f0, #f0f0f0);
      background-color: #f0f0f0;
    }
  }
  &__text{
    width: 100%;
    padding: 0 20px;
    p{
      font-size: 16px;
      font-weight: 300;
    } //p
  } //__text
  &__title{
    height: 60px;
    line-height: 60px;
    padding: 0 20px;
    width: 100%;
    font-size: 20px;
    font-weight: 300;
    color: $color-pink;
    cursor: pointer;
    background: linear-gradient(to bottom, #f0f0f0, transparent 66%);
    background-color: transparent;
    transition: .5s;
    position: relative;

    &:after{
      content: '';
      width: 10px;
      height: 10px;
      border-left: 2px solid $color-pink;
      border-bottom: 2px solid $color-pink;
      transform: rotate(-45deg);
      position: absolute;
      top: 33%;
      #{$left}: 20px;
    }
  } //__title
} //item-text

.item-info{
  padding-#{$left}: 20px;
  border-bottom: 1px solid #ccc;

  @include respond-to('medium'){
    padding-left: 15px;
    padding-right: 15px;
  }

  &__side{
    border-#{$left}: 1px solid #000;
    padding-#{$left}: 80px;
  }

  &__title{
    font-size: 37px;
    font-weight: 700;
    letter-spacing: -.01em;
    color: #000;
    margin: 0;
  }
  p, h2, h3{
    margin-bottom: 25px;
    font-weight: 300;
    text-align: justify;
  }
  p{
    font-size: 17px;
    line-height: 26px;
    letter-spacing: -.01em;
    color: #878a8e;

    @include respond-to('small'){
      font-size: 16px;
    }
  } //p
  h2{
    font-size: 23px;
    color: #000;
    letter-spacing: -.01em;
    margin-bottom: 20px;

    @include respond-to('small'){
      font-size: 20px;
    }
  } //h2
  h3{
    font-size: 26px;
    color: #000;

    @include respond-to('small'){
      font-size: 20px;
    }
  } //h3

  ul{
    margin-bottom: 25px;
    li{
      position: relative;
      padding-#{$right}: 35px;
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 300;
      &:before{
        content: url(/assets/images/bullet.png);
        position: absolute;
        #{$right}: 20px;
        top: -3px;
      } //before
    } //li
  } //ul
} //item-info

.item-content{
  font-size: $item-content-size;
  color: $item-content-color;
  padding: $item-content-padding-v $item-content-padding-h;

  p{
    margin-bottom: $item-content-para-space;
  }
}
.page-separator{
  width: 100%;
  height: 1px;
  background-color: #ccc;
  margin: 50px 0 30px;
  float: $right;
}
.cat-num{
  font-size: 18px;
  color: #777;
  display: block;
  margin: 0 0 35px;
  font-weight: 300;
} //cat-num
