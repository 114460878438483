.j-form{

  ::-webkit-input-placeholder {
    color: $j-form-input-placeholder-color;
  }
  ::-moz-placeholder {
    color: $j-form-input-placeholder-color;
  }
  :-ms-input-placeholder {
    color: $j-form-input-placeholder-color;
  }
  :-moz-placeholder {
    color: $j-form-input-placeholder-color;
  }


  @at-root #{&}__input{
    border-radius: $j-form-input-corner;
    border: $j-form-input-border;
    margin-bottom: $j-form-input-foot-space;
    padding: $padding-base;
    background-color: $j-form-text-input-bg;

  } //__input
  @at-root #{&}__text-input{
    width: $j-form-text-input-width;
    height: $j-form-text-input-height;

  } //__text-input

  textarea{
    resize: none;
    width: $j-form-textarea-width;
    height: $j-form-textarea-height;

  } //textarea
} //j-form
