.content-banner{
  position: relative;
  height: 240px;
  background-size: cover;
  background-repeat: no-repeat;
  //margin-top: 90px;

  @include respond-to('medium'){
    margin-top: 70px;
    height: 500px;
    max-height: 60vh;
    background-position: center;
    background-size: auto 100%;
  }
  @include respond-to('small'){
    max-height: 30vh;
  }

  @at-root #{&}__overlay{
    position: absolute;
    top: 0;
    #{$left}: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: .5;
  } //__overlay

  @at-root #{&}__text{
    max-width: 900px;
  } //__text

  @at-root #{&}__title{
    font-size: 28px;
    font-weight: 800;
    margin-bottom: 20px;
    text-align: center;
    position: absolute;
    z-index: 0;
    top: 50%;
    #{$left}: 50%;
    transform: translate(-50%, -50%);
  } //__title

  @at-root #{&}__sub{
    font-size: 30px;
    font-weight: 400;
  } //__sub

  @at-root #{&}__img{
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    //background-attachment: fixed;
  } //__img
} //content-banner

.content{
  @at-root #{&}__title{
    padding-bottom: 35px !important;
  }
  @at-root #{&}__h3{
    h3{
      font-weight: 900;
      font-size: 24px;
      color: $color-black;
    }
  }
}

.breadcrumb {
  margin: 0;
  position: absolute;
  bottom: -40px;
  right: 0;

  &>li{
    a{
      color: #000;
      font-size: 16px;
      font-weight: 400;
    } //a
    &+li{
      &:before{
        color: #000;
      } //before
    } //li
  } //li

  &>.active{
    color: #000;
  } //active
} //breadcrumb

.menu-container{
   @include respond-to('large'){
     position: fixed;
     top: 0;
     #{$right}: -17px;
     max-width: 80%;
     width: 300px;
     padding-#{$right}: 17px;
     overflow: hidden;
     height: 100%;
     transform: translateX(calc(100% - 50px));
     transition: .2s;
   }
} //menu-container

.content-menu-side{
  max-width: 285px;
  margin-#{$right}: 50%;

  @include respond-to('large'){
    position: absolute;
    #{$right}: 17px;
    top: 0px;
    //box-shadow: 0 0 20px rgba(0,0,0,.2);
    //max-width: 80%;
    width: calc(100% + 3px);
    //background-color: #fff;
    background: linear-gradient(to right, transparent 47px, #777 47px, #777 48px, #fff 48px, #fff 100%);
    margin: 0;
    height: 100vh;
    max-height: unset;
    z-index: 10;
    padding: 100px 15px 0 62px;
  //  transform: translateX(calc(100% - 30px));
    transition: .3s;
  } //---medium
  &__btn{
    position: absolute;
    top: 170px;
    left: 20px;
    width: 60px;
    height: 60px;
    background-color: #fff;
    display: none;
    border-radius: 50%;
    border-left: 1px solid #aaa;

    &:after{
      content: '';
      border: 1px solid transparent;
      border-bottom-color: #000;
      border-left-color: #000;
      position: absolute;
      top: 26px;
      left: 10px;
      width: 8px;
      height: 8px;
      transform: rotate(45deg);
    }

    @include respond-to('large'){
      display: block;
    }
  } //__btn

  &_revealed{
    transform: translateX(0);
    z-index: 1;
  }
} //content-menu-side

.content-menu{
  width: 100%;
  padding: 50px 0px 0px;

  span{
    font-size: 26px;
    font-weight: 300;
    color: $color-pink;
  }

  &__bg{
    //background: linear-gradient(to right, #efefef, #fff 40%);
    z-index: 1;
  }
  @at-root #{&}__list{
    border-top: 1px solid #e0e0e0;
    width: 100%;
    margin-top: 30px;
  }

  @at-root #{&}__list-item{
    position: relative;
    border-bottom: 1px solid #e0e0e0;

    @at-root #{&}_active, &:hover {
      border-bottom-color: $color-pink;
      a{
        color: $color-pink !important;
      } //a
    } //_active

    a{
      color: #777;
      font-size: 18px;
      line-height: 40px;
      font-weight: 300;
      transition: .3s;
    } //a
  } //li
} //content-menu
.content-summary{
  text-align: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 50px;
  p{
    font-size: 18px;
    line-height: 23px;
    color: #4e4e4e;
    font-weight: 400;
  } //p
} //content-summary
.content-title{
  padding-top: 15px;
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 30px;
  text-align: center;
  font-size: 25px;
  font-weight: 700;
  color: $color-navy;
  display: block;
  position: relative;

  &_no-banner{
    margin-top: 55px;
  } //_no-banner
  &_news{
    margin-top: 135px;
  }

  &:before{
    content: url(../images/title_top.png);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%,-50%);
  }

  &:after{
    content: url(../images/title_bottom.png);
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%,50%);
  } //after
} //content-title
.content-main{
  width: 100%;
  margin-bottom: 100px;
  background-color: #fff;
  position: relative;
  box-shadow: 0 0 21px rgba(0,0,0,.04);
  border: 1px solid #e5ded0;

  &_separated{
    &:after{
      content: '';
      width: 1px;
      height: 100%;
      background-color: #e5ded0;
      position: absolute;
      top: 0;
      #{$right}: calc(25% - 21px);

      @include respond-to('medium'){
        #{$right}: calc(33% - 18px);
      }
      @include respond-to('small'){
        display: none;
      }
    }
  }

  &__text{
    padding: 25px 100px 100px;

    &_form-info{
      padding: 60px 90px 100px;
    }

    a{
      font-weight: 600;
      padding-#{$right}: 15px;
    }

    p, span{
      color: #4e4e4e;
      font-size: 18px;
      line-height: 29px;
      font-weight: 400;
      margin-bottom: 15px;
    }

    @include respond-to('huge'){
      float: none;
      max-width: unset;
      width: 100%;
      padding: 20px;
    } //---medium

    hr{
      margin-bottom: 40px;
    }

    h3, h2{
      font-weight: 600;
      color: $color-navy;
      margin-bottom: 15px;
      border-bottom: 1px solid #c3b69d;
      display: inline-block;
      margin-top: 0;
    }
    h2{
      font-size: 22px;
    }
    h3{
      font-size: 18px;
    }
  } //__text
  &__image{
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 365px;
    overflow: hidden;

    @include respond-to('small'){
      height: 250px;
    }
    @include respond-to('extra-small'){
      height: 160px;
    }

    img{
      opacity: 0;
    }
  } //__image
  &__background{
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    &_fixed{
      background-position: left 430px;
      background-attachment: fixed;
      background-repeat: no-repeat;
    } //_fixed
  } //__background
} //content-main

.content-menu-side{

}

.content-min-height{
  min-height: 1030px;

  @include respond-to('small'){
    min-height: 100vh;
    overflow-y: auto;
  }

  @at-root #{&}_extra10{
    min-height: 1040px;
  }
}

.content-icn-menu{
  margin-top: 40px;

  @at-root #{&}__list{

  } //__list
  @at-root #{&}__list-item{
    position: relative;
    height: 60px;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,.05);
    transition: .3s;
    margin-bottom: 10px;

    &:hover{
      span{
        color: #fff !important;
      }
    }

    svg{
      height:40px;
      width:40px;
      float:$right;
    } //svg
    span{
      display: block;
      float:$right;
      margin-#{$right}: 24px;
      line-height: 20px;
      transition: .3s;

      &:first-of-type{
        font-size: 18px;
        font-weight: 900;
      } //1st
      &:last-of-type{
        color: #777;
        font-size: 16px;
        font-weight: 400;
      } //last
    } //span
  } //__list-item
} //content-icn-menu

.content-news{
  margin-top: 100px;
  &__title{
    font-size: 26px;
    font-weight: 300;
    color: $color-pink;
    padding-bottom: 20px;
    margin: 0;
  } //__title
} //content-news

.search-results{
  color: #777;
  font-size: 18px;
  font-weight: 300;

  span{
    color: $color-pink;
  }
} //search-results

.content-form{
  //border: 1px solid $color-pink;
  padding: 25px 35px;
  position: relative;
  box-shadow: 0 0 25px rgba(0,0,0,.1);
  background-color: #fff;

  @include ph-color(#777);
  @include ph-size(18px, 300);

  &:after{
    content: url(../images/form_tag.png);
    position: absolute;
    top: -7px;
    #{$left}: -7px;
  } //after
  &__input{
    border: none;
    margin-top: 30px;
    width: 100%;
    background-color: transparent;
  } //__input
  &__input-text{
    border-bottom: 1px solid #d2d2d2;
    background-color: transparent;

    &:first-of-type{
      margin-top: 0;
    }
  } //__input-text
  &__textarea{
    border-bottom: 1px solid #d2d2d2;
    resize: none;
    background-color: transparent;
  } //__textarea
  &__submit{
    background-color: $color-green;
    color: #fff;
    font-weight: 300;
    font-size: 18px;
    padding: 8px;
  } //__submit
} //content-form

.acc{
  @at-root #{&}__content-title{
    display: block;
    float: right;
    text-align: left;
    font-size: 18px;
    font-weight: 900;
    color: #000;
    width: 12.5%;

    @include respond-to('medium'){
      width: 10%;
    } //---medium
  } //__title
  @at-root #{&}__content-text{
    display: block;
    float: right;
    font-size: 18px;
    font-weight: 300;
    color: #000;
    width: 56.25%;
    padding: 0 25px;

    @include respond-to('medium'){
      width: 60%;
    } //---medium
  } //__content-text
  @at-root #{&}__send{
    display: block;
    width: 31.25%;
    float: right;
    padding: 0 20px;

    @include respond-to('medium'){
      width: 30%;
    } //---medium

    span{
      font-size: 18px;
      font-weight: 900;
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    } //span
  } //__send
  @at-root #{&}__btn{
    font-size: 20px;
    font-weight: 300;
    padding: 15px;
    width: 100%;
    margin-top: 15px;
    border-radius: 0;
    text-align: center !important;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium

    &:active{
      padding: 16px 14px 16px 14px;
    } //active
  } //__btn
  @at-root #{&}__open-btn{
    height: 60px;
    width: 60px;
    border-left: solid 1px #e1e1e1;
    position: relative;
    float: right;

    @include respond-to('small'){
      width: 30px;
      position: absolute;
      float: none;
    } //---small

    &:before{
      position: absolute;
      content:'';
      top: 50%;
      left: 45%;
      width: 10px;
      height: 10px;
      border: 2px solid #777;
      border-top-color: transparent;
      border-left-color: transparent;
      transform: rotate(45deg) translate(-50%, -50%);
      z-index: 1;
      display: block;
      transition: .1s;
    } //before
    &:after{
      width: 40px;
      height: 40px;
      position: absolute;
      content: '';
      background-color: #f4f4f4;
      border: 1px solid #e5e5e5;
      top: 10px;
      right: 10px;
      transition: .2s;

      @include respond-to('small'){
        width: 30px;
        height: 60px;
        top: 0;
        right: 0;
      } //---small
    } //after
  } //__open-btn
  @at-root #{&}__link{
    position: relative;

    &:not(.collapsed){
      .panel-title, .acc__location, .acc__number{
        color: #000;
      } //panel-title
      .acc__open-btn{
        &:after{
          background-color: $color-green;
        } //after
        &:before{
          transform: rotate(-135deg) translate(15%,-5%);
          border-bottom-color: #fff;
          border-right-color: #fff;
        } //before
      } //acc__open-btn
      .acc__location{
        display: block;
      }
      .acc__number{
        display: block;
      }
    } //not collapsed
  } //__link
  @at-root #{&}__location{
    width: 21%;
    font-size: 20px;
    color: #777;
    font-weight: 400;
    padding-top: 19px;
    float: right;
    padding-right: 15px;
    display: block;
    height: 60px;
    line-height: 1.1;
    border-left: 1px solid #e5e5e5;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium
    @include respond-to('small'){
      height: 30px;
      padding-top: 10px;
      width: 75%;
      margin-bottom: 10px;
      display: none;
    } //---small
  } //__location
  @at-root #{&}__number{
    font-size: 20px;
    color: #777;
    font-weight: 400;
    padding-top: 19px;
    float: right;
    padding-right: 15px;
    display: block;
    height: 60px;
    line-height: 1.1;

    @include respond-to('medium'){
      font-size: 16px;
    } //---medium
    @include respond-to('small'){
      height: 30px;
      padding-top: 10px;
      width: 25%;
      display: none;
    } //---small
  } //__number
} //acc

.panel-group{
  .panel{
    border-radius: 0;
    border: none;
    border-top: 1px solid #cacaca;

    &+.panel{
      margin-top: 0;
    }

    &:nth-of-type(even){
      .panel-heading{
        background-color: #f4f4f4;
      }
    } //even

    .panel-body{
      background-color: #f2f2f2;
      box-shadow: 0 0 20px rgba(0,0,0,.25) inset;
    } //panel-body
  } //panel

  .panel-heading{
    background-color: #fff;
    box-shadow: 0 0 20px rgba(0,0,0,.25);

    a{
      display: block;
      width: 100%;
      height: 60px;
      padding: 0px;
    } //a
    //z-index: 1;
    //position: relative;

    &+.panel-collapse{
      &>.panel-body{
        border-top: none;
      }
    }
  }
} //panel-group

.panel-title{
  font-size: 20px;
  color: #777;
  font-weight: 400;
  padding-top: 19px;
  float: right;
  padding-#{$right}: 15px;
  display: block;
  height: 60px;
  width: calc(70% - 60px);
  border-left: 1px solid #e5e5e5;

  @include respond-to('medium'){
    font-size: 16px;
  } //---medium
  @include respond-to('small'){
    padding-top: 10px;
    padding-#{$right}: 40px;
    border: none;
    width: 100%;
  } //---small
} //panel-title

.panel-heading{
  padding: 0;
} //panel-heading

.content-small-full{
  @include respond-to('small'){
    width: 100%;
    float: #{$right};
    text-align: #{$right};
    padding-right: 15px;
    padding-left: 15px;
  }
}

.form-info{
  &__title{
    font-size: 18px;
    font-weight: 700;
    color: $color-navy;
    margin-bottom: 40px;
  } //__title
  &__img{
    padding-top: 5px;
  } //__img
  &__text{
    margin-bottom: 20px;
    color: #939393;
    font-size: 18px;
    line-height: 29px;

    a{
      color: inherit;
    }
  } //__text
  &__text-title{
    font-size: 18px;
    color: $color-navy;
    font-weight: 600;
    border-bottom: 1px solid #c3b69c;
    margin-bottom: 10px;
    display: inline-block;
  } //__text-title
} //form-info
