/*body.scrolling .main-header{
  .header-desktop{
     height: 30px;
  } //header-desktop
  .header-logo{
    height: 30px;
    &:after{
      height: 30px;
    }
    &__under{

    } //__under
    &__image{
      width: 147px;
    } //__image
  } //header-logo
  .header-main{
    &__menu-item{
      padding: 0 20px;
      a{
        font-size: 17px;
      }
    } //__menu-item
  } //header-main
} //SCROLLING main-header
*/
.main-header{
  width: 100%;
  position: fixed;
  #{$left}: 0;
  top: 0;
  z-index: 100;
  transition: .2s;
}

.header-bg{
  width: 100%;
  height: 100%;
  background-color: #fff;
  //background-image: url(/assets/images/header_bg.jpg);
  //background-position: center;
  //background-size: cover;
  top: 0;
  #{$left}: 0;
  position: absolute;
}

.header-desktop{
  height: 120px;
  position: relative;
  top: 0;
  transition: .3s;
  width: 100%;
  box-shadow: 0 0 21px rgba(0,0,0,.56);
  background-color: #fff;

  @include respond-to('large'){
    //height: 120px;
  }
  @include respond-to('medium'){
    display: none;
  }
/*
  &_home{
    .header-main{
      padding-top: 1px;
      text-align: center;
    } //header-main
    .header-logo{
      display: none;
    } //header-logo
    .header-main__menu-container{
      float: none;
      margin: 0 auto;
      display: inline-block;
    }
  } //_home */
} //header-desktop

.header-wrapper{
  max-width: $max-width;
  padding: $padding-base;
} //header-wrapper

.header-logo{
  display: block;
  max-height: 100%;
  height: 75px;
  width: 400px;
  transition: .3s;
  margin: 0 auto;
  position: relative;

/*  &:after{
    content: '';
    width: 160%;
    height: 45px;
    background-color: #fFF;
    position: absolute;
    top: 0;
    left: -30%;
    z-index: 2;
    transition: .3s;
  }*/

  @include respond-to('large'){

  }

  &__image{
    display: block;
    //width: 206px;
    transition: .3s;
    position: relative;
    z-index: 3;
  }

  &__under{
    filter: drop-shadow(0 0 10px rgba(0,0,0,.33));
    position: absolute;
    top: -1px;
    left: 0;
    z-index: 1;
  } //__under
} //header-logo

.header-logo__image{
  max-height: 100%;
  transition: .2s;
} //header-logo__image

.header-main{
  padding: 0 15px 0 0;

  @include respond-to('huge'){
    padding-#{$right}: 10px;
  } //---huge

  &__menu-container{
    text-align: center;
    //position: absolute;
    //float: $left;
    //transform: translateX(-50%);


  } //__menu-container
  &__contact{
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    padding: 55px 40px;
    background-color: $color-aqua;
    color: #fff;
    cursor: pointer;
  } //__contanct
} //header-main

.header-bottom{
  width: 100%;
  //height: 100px;
  position: absolute;
  top: 40px;
  #{$left}: 0;
  transition: .3s;
} //header-bottom

.header-main__menu{
  //border-top: 1px solid $color-silver;
  margin-top: 3px;
  position: relative;
  z-index: 4;
  //margin-#{$right}: 50px;
}
.header-main__menu-item{
  display: inline-block;
	padding: 5px 20px 0;
  margin: 0px;
  position: relative;
  text-align: center;


  @include respond-to('huger'){
    margin: 0 5px;
    font-size: 16px;
  }
  @include respond-to('large'){

  }

  &:before{
    content: '|';
    color: #a8abac;
    font-size: 12px;
    font-weight: 600;
    margin-#{$left}: 15px;
    position: relative;
    top: -3px;
  }

  &:after{
    position: absolute;
    content: '';
    opacity: 0;
    transition: .3s;
    top: 0px;
    width: 100%;
    height: 4px;
    #{$right}: 30px;
    background-color: $color-blue;
  } //after

  &:not(:last-of-type){
    margin-#{$left}: 25px;
    //padding-#{$left}: 22px;
  } //not last


	a{
		color: $color-navy;
    font-size: 19px;
    font-weight: 600;
    transition: .1s;
	}

	&:hover, &.header-main__menu-item_selected{
    &:after{
      opacity: 1;
    }
    >a{
      //font-weight: 600;
      color: $color-blue;
      text-shadow:  .5px -.5px 0px $color-blue,
                    .5px .5px 0px $color-blue,
                    -.5px .5px 0px $color-blue,
                    -.5px -.5px 0px $color-blue;
    }
	}

	@at-root #{&}_shadow{
		text-shadow: 	$header-menu-shadow-x
									$header-menu-shadow-y
									$header-menu-shadow-size
									$header-menu-shadow-color;
	} //_shadow

	@at-root #{&}_space-reduced{
		padding-#{$left}: $header-menu-space-reduced / 2;
		padding-#{$right}: $header-menu-space-reduced / 2;
	} //_space-reduced

	@at-root #{&}_border{
		@at-root #{&}_none{
			border: none !important;
		} //_none
	} //_border_none

	@at-root #{&}_color{
		border-top: 9px solid transparent;
		transition: .2s;

		@at-root #{&}_orange{
			@include menu-item-color($color-orange);
		} //_orange
		@at-root #{&}_turquise{
			@include menu-item-color($color-turquise);
		} //_turquise
		@at-root #{&}_fuxia{
			@include menu-item-color($color-fuxia);
		} //_fuxia
		@at-root #{&}_green{
			@include menu-item-color($color-green);
		} //_green
		@at-root #{&}_blue{
			@include menu-item-color($color-deep-blue);
		} //_blue
		@at-root #{&}_red{
			@include menu-item-color($color-red);
		} //_red
	} //_color

} //header-main__item
.header-main__menu-item_parent{
  position: relative;

  &:hover{
    ul{
      display: block;
    }
  } //hover

  ul{
    background-color: #fff;
    position: absolute;
    top: 42px;
    right: 20px;
    display: none;
    border: 1px solid #d1c3a5;

    &:before{
      content: '';
      width: 100%;
      position: absolute;
      height: 35px;
      top: -35px;
      background-color: transparent;
      right: 0;
    } //before
    &:after{
      content: '';
      position: absolute;
      top: -5px;
      #{$right}:30px;
      transform: rotate(-45deg);
      border: 5px solid #d1c3a5;
      border-left-color: transparent;
      border-bottom-color: transparent;
    } //after

    li{
      padding: 0;
      transition: .2s;
      white-space: pre;

      &:first-of-type{
        padding-top: 10px;
      }
      &:not(:last-of-type){
        border-bottom: 1px solid #d6dddf;
        padding-bottom: 10px;
      } //not last

      &:hover{
        background-color: #f3f1ee;
        >a{
          //color: $color-blue;
        }
    	}

      a{
        display: block;
        width: 100%;
        height: 100%;
        padding: 10px 40px;
      }
    } //li
  } //ul
} //header-main__item_parent

.header-cart{
  position: relative;
  float: $left;
  width: 100px;
  height: 65px;
  border-#{$right}: 1px solid #000;
  padding-#{$right}: 20px;
  text-align: center;
  margin-top: 25px;
  &__img{
    display: block;
    margin: 15px auto -10px;
  } //__img
  &__number{
    background-color: $color-fuxia;
    color: #fff;
    position: absolute;
    top: 10px;
    #{$right}: 25px;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    text-align: center;
    display: block;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: -.01em;
  } //__number
  &__text{
    font-size: 13px;
    letter-spacing: -.01em;
    font-weight: 600;
    color: #000;
    white-space: pre;
  } //__text
} //header-cart

.header-search{
  position: relative;
  float: $left;
  margin-top: 17px;
  margin-#{$left}: 0px;
  transition: .2s;

    &_open{
      .header-search__form{
      //box-shadow: 0 0 15px rgba(0,0,0,.35);
      margin-#{$left}: 20px;
    } //__form
    .header-search__form-close{
      opacity: .6;
    } //__form-close
    .header-search__input{
      width: 200px;
      opacity: 1;
    } //__input
  } //_open

  &__form-close{
    position: absolute;
    color: #000;
    font-weight: 600;
    top: 12px;
    #{$right}: 6px;
    opacity: 0;
    transition: .3s;
    cursor: pointer;

    &:after{
      //content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      top: 9px;
      #{$right}: -2px;
      cursor: pointer;
      border: 1px solid #fff;
    } //after
  } //__form-close
  &__form{
    float: $left;
    margin-top: 30px;
    padding: 10px;
    padding-#{$right}: 30px;
    margin-top: 15px;
    border-radius: 3px;
    background-color: transparent;
    color: #fff;

    @include respond-to('large'){

    }

    &:hover{

    }
  } //__form
  &__input{
    //height: 40px;
    vertical-align: middle;
    border: none;
    border-bottom: 1px solid #000;
    background-color: transparent;
    padding-#{$right}: 10px;
    width: 0px;
    opacity: 0;
    transition: .3s;

    @include respond-to('large'){

    }
  } //__input
  &__submit{
    vertical-align: middle;
    border: none;

    @include respond-to('large'){

    }
  } //__submit
} //header-search

.header-sub{
  position: fixed;
  top: 120px;
  left: 0;
  width: 100vw;
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
  display: none;

  &__show{
    display: block;
  }
}

.header-cats{
  width: 100%;
  background-color: #fff;
  background-image: url(/assets/images/cats_bg.jpg);
  background-position: left bottom;
  background-repeat: no-repeat;
  border: 1px solid #e7e8eb;

  &__banner{
    padding-top: 100%;
  }

  &__content{
    padding: 90px;
  } //__content
  &__cat{
    width: 20%;
    float: $right;
    padding: 0 15px;

    @include respond-to('small'){
      width: 50%;
    }
    @include respond-to('extra-small'){
      width: 100%;
    }

    &:hover{
      .header-cats__title{
        color: $color-fuxia;
      }
    }
  } //__cat
  &__title{
    font-size: 20px;
    font-weight: 700;
    color: #000;
    transition: .3s;
  } //__title
  &__list{
    margin-top: 15px;
    margin-bottom: 40px;
    li{
      margin-bottom: 5px;
      a{
        color: #747474;
        font-size: 17px;
        font-weight: 400;
        letter-spacing: -.01em;
      }
    }
  }
} //header-cats

.header-brands{
  width: 100%;
  height: 100%;
  background-color: $color-fuxia;
  background-position: center;
  background-repeat: no-repeat;
  padding: 125px 0;

  &__brand{
    width: 20%;
    float: $right;
    padding: 35px 15px;
    text-align: center;

    @include respond-to('small'){
      width: 50%;
    }
    @include respond-to('extra-small'){
      width: 100%;
      padding: 15px;
    }
  } //__brand
  &__link{
    display: block;
    width: 100%;
    height: 100%;
  } //__link
  &__text{
    font-size: 35px;
    letter-spacing: -.03em;
    color: #fff;
    font-weight: 600;
  } //__text
} //header-brands

.header-phone{
  position: relative;
  text-align: center;
  font-size: 22px;
  margin-top: 27px;
  color: #4b576d;
  display: block;
  direction: ltr;

  .fa-phone{
    transform: scale(-1,1);
  }

  &_mobile{
    float: right;
    margin-right: 15px;
    font-size: 35px;
    margin-top: 0;
  }
}
//Header for mobile devices
.header-mobile{
  display: none;

  @include respond-to('medium'){
    display: block;
  }

  .header-bg{
    background: unset;
    background-color: #fff;
  }
  .header-mobile-logo{
    max-width: 60%;
    display: block;
    float: $left;
    position: relative;
    height: 50px;
    padding: 5px 15px;

    img{
      margin-#{$left}: 10px;
      max-height: 100%;
    } //img
  } //header-mobile-logo

  .mobile-menu-btn{
    margin-top: 10px;
    margin-#{$right}: 10px;
  }
} //header-mobile

.j-prev,
.j-next {
    position: absolute;
    display: block;
    height: 25px;
    width: 25px;
    cursor: pointer;
    background-color: #fff;
    bottom: 0;
    color: transparent;
    border: 1px solid #000;
    z-index: 10000;
    #{$left}: 240px;

    &:after{
      content: '';
      width: 8px;
      height: 8px;
      border: 1px solid transparent;
      border-left-color: #000;
      border-bottom-color: #000;
      transform: translate(-50%,-50%) rotate(45deg);
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:hover /*, &:focus */ {
      background-color: #000;
      &:after{
        border-left-color: #fff;
        border-bottom-color: #fff;
      }
    }
}

.j-prev {

  &:after{
    transform: translate(-50%,-50%) rotate(-135deg);
  }

    [dir="rtl"] & {

    }
}

.j-next {
    transform: translate(-100%, 0);
    [dir="rtl"] & {

    }
}
